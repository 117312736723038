import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "fullnamebox", "emailbox" , "subjectbox", "descriptionbox" ]

  send_response(){
    const error = this.validate([this.fullnameboxTarget, this.emailboxTarget, this.subjectboxTarget, this.descriptionboxTarget])
    if (error) {
      this.errorNotify("Please fill all fields")
    } else {
      this.fullnameboxTarget.closest(".contact-us-form").submit()
    }
  }

  validate(targets_array){
    let error = false;
    targets_array.forEach((elem=>{
      if (elem.value.trim() == '') {
        elem.classList.remove("border-0")
        elem.classList.add("border")
        elem.classList.add("border-danger")
        error = true;
      } else {
        elem.classList.remove("border")
        elem.classList.remove("border-danger")
        elem.classList.add("border-0")
      }
    }))
    return error
  }

  successNotify(message){
		Lobibox.notify('success', {
			showClass: 'fadeInDown',
			hideClass: 'fadeUpDown',
			width: 400,
			sound: false,
			icon: false,
			msg: message,
			position: 'top right',
		});
	}

	errorNotify(message){
		Lobibox.notify('error', {
			showClass: 'fadeInDown',
			hideClass: 'fadeUpDown',
			width: 400,
			sound: false,
			icon: false,
			msg: message,
			position: 'top right',
		});
	}
}
