import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "pluspractice", "minuspractice" , "numpractices", "pricepractices" ]
  per_practice = 30

  connect(){
    let upgrade_form = this.numpracticesTarget.closest(".modal-body").querySelector(".button_to");
    this.numpracticesTarget.value = 1;
    this.pricepracticesTarget.textContent = '£30';
    this.createAndAppendElement(upgrade_form);
  }

  increment(){
    let practices = parseInt(this.numpracticesTarget.value) + 1;
    this.changePlanValues(practices);
  }

  decrement(){
    let practices = parseInt(this.numpracticesTarget.value);
    practices = practices > 1 ? practices - 1 : 1
    this.changePlanValues(practices);
  }

  changePlanValues(practices){
    document.querySelector(".num_practices").value = practices;
    this.numpracticesTarget.value = practices;
    this.pricepracticesTarget.textContent = `£${practices * this.per_practice}`;
  }

  createAndAppendElement(form){
    var input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'practices';
    input.className="num_practices"
    input.value = '1';
    form.appendChild(input);
  }
}
